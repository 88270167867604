import React from "react"
import Header from "../components/header"
import Footer from "../components/footer"
import layoutStyles from "./layout.module.scss"
import { Row, Col } from "antd"

const Layout = props => {
  return (
    <div className={layoutStyles.container}>
      <div className={layoutStyles.content}>
        <Row>
          <Col span={24}>
            <Header />
            {props.children}
          </Col>
        </Row>
      </div>
      <Row>
        <Col span={24}>
          <Footer />
        </Col>
      </Row>
    </div>
  )
}

export default Layout
