import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { Row, Col, Tooltip } from "antd"
import footerStyles from "./footer.module.scss"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          author
        }
      }
    }
  `)

  return (
    <footer className={footerStyles.footer}>
      <Row>
        <Col
          span={12}
          offset={6}
          xs={{ span: 16, offset: 1 }}
          md={{ span: 16, offset: 4 }}
          lg={{ span: 16, offset: 4 }}
        >
          <div className={footerStyles.address}>
            <h4>Anand Multi-Specialty Hospital</h4>
            <p>
              <Tooltip title="Click for directions" placement="right">
                <a
                  href="https://maps.google.com/maps?ll=23.195095,72.614581&z=17&t=m&hl=en-US&gl=US&mapclient=embed&daddr=Anand%20Multispeciality%20Hospital%204th%20Floor%2C%20Sarthak%20Mall%20Mahatma%20Mandir%20Road%20Cross%20Road%2C%20Sargasan%20Gandhinagar%2C%20Gujarat%20382421%2C%20India@23.1950795,72.6145113"
                  target="_blank"
                >
                  4th Floor Sarthak Mall,
                  <br />
                  Mahatma Mandir Road,
                  <br />
                  Sargasan Cross Road,
                  <br />
                  Gandhinagar, Gujarat 382421
                  <br />
                  <br />
                </a>
              </Tooltip>
              <Tooltip title="Click to book an appointment" placement="right">
                <a href="tel:+91-6351901836">
                  Phone: 6351901836
                  <br />
                </a>
              </Tooltip>
            </p>
          </div>
          <div className={footerStyles.hours}>
            <h4>Consulting hours</h4>
            <p>
              Monday: 10am - 9pm
              <br />
              Tuesday: 10am - 9pm
              <br />
              Wednesday: 10am - 9pm
              <br />
              Thursday: 10am - 9pm
              <br />
              Friday: 10am - 9pm
              <br />
              Saturday: 10am - 9pm
              <br />
            </p>
          </div>
        </Col>
      </Row>
      <Row>
        <Col
          span={12}
          offset={6}
          xs={{ span: 20, offset: 1 }}
          lg={{ span: 12, offset: 6 }}
        >
          <div className={footerStyles.copyright}>
            <p>
              Created by{" "}
              <a href="https://thrivesoftsolutions.com" target="_blank">
                {data.site.siteMetadata.author}
              </a>
              , © 2020
            </p>
          </div>
        </Col>
      </Row>
    </footer>
  )
}

export default Footer
