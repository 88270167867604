import { useState, useEffect } from "react"

const useShowOnScrolled = position => {
  const [shown, setShown] = useState(false)

  const handleScroll = () => {
    const top = window.pageYOffset || document.documentElement.scrollTop
    setShown(top >= position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll)

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  return shown
}

export default useShowOnScrolled
