import React from "react"
import useShowOnScolled from "../useShowOnScrolled"
import { Link, graphql, useStaticQuery } from "gatsby"
import { Affix, Icon } from "antd"
import headerStyles from "./header.module.scss"

const HamburgerMenu = () => {
  return (
    <div className={headerStyles.hamburgerTopMenu}>
      <div className={headerStyles.hamburgerMenuListItem}>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/"
        >
          Home
        </Link>
      </div>
      <div className={headerStyles.hamburgerMenuListItem}>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/services"
        >
          Services
        </Link>
      </div>
      <div className={headerStyles.hamburgerMenuListItem}>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/our_team"
        >
          Our Team
        </Link>
      </div>
      <div className={headerStyles.hamburgerMenuListItem}>
        <Link
          className={headerStyles.navItem}
          activeClassName={headerStyles.activeNavItem}
          to="/contact_us"
        >
          Contact Us
        </Link>
      </div>
    </div>
  )
}

const Header = () => {
  const shown = useShowOnScolled(75)
  const [showBurgerMenu, setShowBurgerMenu] = React.useState(false)

  const headerNavigationStyles = shown
    ? `${headerStyles.header} ${headerStyles.scrolled}`
    : headerStyles.header
  const headerNavigationMenuStyles = shown
    ? `${headerStyles.navList} ${headerStyles.scrolledText}`
    : headerStyles.navList
  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <Affix>
      <header className={headerNavigationStyles}>
        <nav>
          <div>
            <Link className={headerStyles.title} to="/">
              <span>{data.site.siteMetadata.title}</span>
            </Link>
          </div>
          <div className={headerStyles.menu}>
            <div className={headerStyles.hamburgerMenu}>
              <Icon
                type="menu"
                onClick={() => setShowBurgerMenu(!showBurgerMenu)}
              />
            </div>
            {showBurgerMenu ? <HamburgerMenu /> : <div />}
            <ul className={headerNavigationMenuStyles}>
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.activeNavItem}
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.activeNavItem}
                  to="/services"
                >
                  Services
                </Link>
              </li>
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.activeNavItem}
                  to="/our_team"
                >
                  Our Team
                </Link>
              </li>
              <li>
                <Link
                  className={headerStyles.navItem}
                  activeClassName={headerStyles.activeNavItem}
                  to="/contact_us"
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </div>
        </nav>
      </header>
    </Affix>
  )
}

export default Header
